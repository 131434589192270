.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar (for horizontal) */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

::-webkit-scrollbar-track {
  background: #3c3c3c; /* Color of the scrollbar track */
  /* border-radius: 4px; */
  
}

.slide-in {
  /* Initial state */
  transform: translateX(100%); /* Start off-screen to the right */
  opacity: 0;                  /* Start invisible */
  animation: slide-in 0.5s forwards; /* Apply animation */
}

@keyframes slide-in {
  0% {
      transform: translateX(100%); /* Start off-screen to the right */
      opacity: 0;                  /* Start invisible */
  }
  100% {
      transform: translateX(0);     /* End in its original position */
      opacity: 1;                   /* Fade in */
  }
}

.jodit-status-bar__item {
  display: none !important; /* Hide the branding */
}